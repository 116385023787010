import styled from 'styled-components'
import Text from './Text'

// extend Text component
const Heading = styled(Text)({
    textDecoration: 'none',
})

Heading.defaultProps = {
    as: 'h2',
    m: 0,
    fontSize: 4,
    fontWeight: '600',
}

export default Heading