import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledLink = styled(props => <Link {...props} />)`
  display: inline-block;
  color: hsl(227, 17%, 27%); 
  text-decoration: none !important;
  border: 2px solid hsla(0, 0%, 100%, 0.05);
  padding: 8px 16px;
  text-align: center;

  :hover {
      background: hsla(0, 0%, 0%, 0.05);
      color: hsl(227, 17%, 27%);
  }

  :active,
  :focus {
      outline: 3px solid 3px solid hsl(233.7, 100%, 92.5%);
      outline-offset: 0;

  }
  
`

export default StyledLink