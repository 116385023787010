import styled from 'styled-components'
import Box from './Box'
import { alignSelf, flex, flexBasis, order } from 'styled-system'

// extend Box component
const FlexItem = styled(Box)({
    boxSizing: 'border-box',
},
    alignSelf,
    flex,
    flexBasis,
    order,
)

export default FlexItem