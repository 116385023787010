import styled from 'styled-components'
import Box from './Box'
import { fontFamily, fontWeight, textAlign, lineHeight, letterSpacing } from 'styled-system'

// extend Box component
const Text = styled(Box)(
    fontFamily,
    fontWeight,
    textAlign,
    lineHeight,
    letterSpacing,
)

Text.defaultProps = {
    as: 'p',
    m: 0,
}

export default Text