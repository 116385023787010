// theme.js
export default {
    breakpoints: ['40em', '52em', '64em'],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    fontSizes: [12, 14, 16, 20, 24, 28, 48, 56, 64],
    fonts: {
      sans: '-apple-system, BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif',
      mono: 'Menlo, monospace',
    },
    fontWeights: [400, 500, 600],
    lineHeights: {
      solid: 1,
      title: 1.25,
      copy: 1.5,
      },
    colors: {
      black: '#333',
      grey:  'hsla(0, 0%, 0%, 0.7)',
      light: 'hsla(0, 0%, 0%, 0.4)',
      lighter: 'hsla(0, 0%, 0%, 0.05)',
      lightest: '#f7f7f7',
      white: '#fff',
      green: '#13CE66',
      blue: '#4353FF',
      lightblue: 'hsl(234.9, 100%, 23.1%)'
    },
    letterSpacings: {
      small: '1px',
      large: '2px',
    },
    shadows: {
      small: '0 0 4px rgba(0, 0, 0, .125)',
      large: '0 0 24px rgba(0, 0, 0, .125)',
    },
    buttons: {
      primary: {
        color: '#fff',
        backgroundColor: 'hsl(234.9, 100%, 63.1%)',
        border: 'solid hsl(234.9,100%,63.1%) 2px',
        '&:hover': {
          color: '#fff',
          backgroundColor: 'hsl(234.9, 100%, 60.1%)',
          textDecoration: 'none',
        },
        '&:active': {
          outline: '3px solid hsl(233.7, 100%, 92.5%)',
          outlineOffset: '0',
        },
        '&:focus': {
          outline: '3px solid hsl(233.7, 100%, 92.5%)',
          outlineOffset: '0',
        }
      },
      secondary: {
        color: 'hsl(227, 17%, 27%)',
        backgroundColor: 'hsla(0, 0%, 0%, 0.05)',
        border: 'solid 2px hsla(0, 0%, 100%, 0.05)',
        '&:hover': {
          color: 'hsl(227, 17%, 27%)',
          backgroundColor: 'hsla(0, 0%, 0%, 0.08)',
          textDecoration: 'none',
        },
        '&:active': {
          outline: '3px solid hsl(233.7, 100%, 92.5%)',
          outlineOffset: '0',
        },
        '&:focus': {
          outline: '3px solid hsl(233.7, 100%, 92.5%)',
          outlineOffset: '0',
        }
      },
    },
    width: [16, 32, 64, 128, 256],
    heights: [16, 32, 64, 128, 256],
    maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  }