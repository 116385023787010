import React from "react";
import Box from '../components/Box'
import Text from '../components/Text'


function Banner (props) {
  return (
  <Box as="section" bg="blue" py="1">
    <Text color="white" fontSize="1" textAlign="center">{props.text}</Text>
  </Box>
  ) 
}

export default Banner