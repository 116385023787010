import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledLink = styled(props => <Link {...props} />)`
  display: inline-block;
  color: white; 
  text-decoration: none !important;
  background: #4353FF;
  border: 2px solid #4353FF;
  padding: 8px 16px;
  text-align: center;

  :hover {
      filter: brightness(120%);
      border: 2px solid #4353FF;
  }

  :active,
  :focus {
      border: 2px solid #4353FF;
      outline: 3px solid #D9DDFF;
      outline-offset: 0;

  }
  
`

export default StyledLink