import styled from 'styled-components'
import { color, space, width, height, border, fontSize, flex, order, alignSelf, maxWidth, display } from 'styled-system'

const Box = styled.div({
    boxSizing: 'border-box',
    minWidth: 0,
},
    space,
    color,
    width,
    maxWidth,
    height,
    fontSize,
    flex,
    order,
    alignSelf,
    border,
    display
)

export default Box