import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Heading from './Heading'
import Banner from './Banner'
import Flex from '../components/Flex'
import FlexItem from '../components/FlexItem'
import StyledLink from '../components/StyledLink'
import StyledLinkSecondary from '../components/StyledLinkSecondary'
import Logo from '../images/ain-logo-beta.svg'

const Header = ({ siteTitle }) => (
    <>
    <Banner text="🎉 Post a job for free whilst we’re in Beta." />
    <Flex mx="auto" maxWidth="1200px" p={['16px', '24px']} justifyContent="space-between" alignItems={['flex-start', 'center']}>
        <Heading as="h1">
          <Link
            to="/"
            style={{
              textDecoration: 'none',
              display: 'block',
            }}
          >
            <img style={{width: '147px'}} src={Logo} alt="Angel Investment Network Logo"/>
          </Link>
        </Heading>
        <Flex as="ul" m="0" p="0" css="list-style: none" justifyContent="space-between" flexDirection={['column', 'row']}>
          <FlexItem as="li" display={['none', 'block']}>
            <StyledLinkSecondary to="/">Home</StyledLinkSecondary>
          </FlexItem>
          <FlexItem as="li" ml="2" order={['2','1']}>
            <StyledLinkSecondary to="/newsletter">Newsletter</StyledLinkSecondary>
          </FlexItem>
          <FlexItem as="li" ml="2" order={['1', '2']}>
            <StyledLink to="/hire-talent">Post a Job</StyledLink>
          </FlexItem>
        </Flex>
    </Flex>
    </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: 'AIN Jobs',
}

export default Header
