import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Container from './Container'
import Flex from './Flex'
import FlexItem from './FlexItem'
import '../css/layout.css'
import styles from './layout.module.css'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import styled from 'styled-components'

const Footer = styled.footer`
  font-size: 14px;
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description, },
            { name: 'keywords', content: 'Invest, Fundraise, Entrepreneur, Investor, Startup, Jobs' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <section className='Skip'>
          <a className={styles.SkipToContent} href='#main-content'>Skip to main content</a>
        </section>
          {children}

          <Footer >
            <Container>
              <Flex justifyContent="space-between">
                <FlexItem>
                  ©2019 Angel Investment Network.
                </FlexItem>
                <FlexItem>
                  <a href="https://www.angelinvestmentnetwork.co.uk/terms-and-conditions" target="_blank" rel="noopener noreferrer" css="margin-right:16px;">Terms</a>
                  <a href="https://www.angelinvestmentnetwork.co.uk/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </FlexItem>
              </Flex>
            </Container>
          </Footer>
       
      </>
    )}
  />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
