import styled from 'styled-components'
import Box from './Box'
import { alignItems, alignContent, justifyItems, justifyContent, flexWrap, flexDirection } from 'styled-system'

// extend Box component
const Flex = styled(Box)({
    display: 'flex'
},
    alignItems,
    alignContent,
    justifyItems,
    justifyContent,
    flexWrap,
    flexDirection,
)

export default Flex